:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._mt-1316332129{margin-top:var(--space-33);}
:root ._mr-1316332129{margin-right:var(--space-33);}
:root ._mb-1316332129{margin-bottom:var(--space-33);}
:root ._ml-1316332129{margin-left:var(--space-33);}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._fd-column{flex-direction:column;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-1316331292{gap:var(--space-27);}
:root ._pt-1481558307{padding-top:var(--space-4);}
:root ._pr-1481558307{padding-right:var(--space-4);}
:root ._pb-1481558307{padding-bottom:var(--space-4);}
:root ._pl-1481558307{padding-left:var(--space-4);}
:root ._w-10037{width:100%;}
@media screen and (min-width: 801px) { :root:root:root ._w-_gtSm_5037{width:50%;} }
:root ._aspectRatio-d0t1183139551{aspect-ratio:1.8076923076923077;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._ai-center{align-items:center;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43818370{color:var(--color-11);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167744121{letter-spacing:var(--f-21-6);}
:root ._fos-229441282{font-size:var(--f-si-6);}
:root ._lh-222976635{line-height:var(--f-li-6);}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._fow-233016078{font-weight:var(--f-we-2);}
:root ._ls-167743997{letter-spacing:var(--f-21-2);}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976511{line-height:var(--f-li-2);}